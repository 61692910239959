.bg-decoration {
    @apply absolute;
    img {
        @apply max-h-[315px] md:max-h-[400px] h-full object-fill;
        width: 82px;
    }
}
#splash.bg-decoration {
    @apply left-0 top-12 md:top-20;

    img {
        @apply w-full max-w-[3rem] md:max-w-[4rem] object-cover;
    }
}
#landing1.bg-decoration {
    @apply  top-8 -left-4  lg:left-12 xl:left-32;
}
#landing2.bg-decoration {
    @apply bottom-[10%] right-0 sm:right-12 xl:right-32;
}

.inner-page {
    .bg-decoration {
        @apply top-[12%] -left-40;
    }
    footer {@apply relative;}
}