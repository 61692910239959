@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    .landing {

        .get-started {
            @apply sticky bottom-0 border-none flex flex-col justify-center items-center mt-0 lg:-left-[175px] lg:top-1/2 lg:m-0 lg:absolute z-20;
            &:hover {
                @apply bg-transparent;

                span {@apply bg-bordeaux;}
            }
            span {
              @apply border border-white text-sm py-2 px-4 mt-8 uppercase;
            }
            img {@apply h-full max-h-[100px] mt-2;}
        }

        form {
            @apply pb-[600px] relative;
        }
    }
}

.portrait-wrap {
    @apply absolute w-full h-full top-[33%] right-0 left-0 m-0 p-0 max-h-[1200px] md:max-h-[600px] z-0;

    img {
        @apply absolute w-full z-0 ;
        &:nth-child(1) {
            @apply max-w-[30%] sm:max-w-[150px] left-0 top-[75rem] md:top-[68rem] lg:top-3/4; 
        }
        &:nth-child(2) {
            @apply max-w-[50%] sm:max-w-[35%]  -top-[5rem] md:-top-10 lg:max-w-[275px] right-0 lg:right-1/4; 
        }
        &:nth-child(3) {
            @apply max-w-[40%] md:max-w-[30%] lg:max-w-[250px] -bottom-60 md:-bottom-[45rem] lg:-bottom-[35rem] right-0;
        }
        &:nth-child(4) {
            @apply max-w-[200px] md:max-w-[250px] lg:max-w-[275px] -bottom-[40rem] md:-bottom-[75rem] lg:-bottom-[55rem] left-6;
        }
        @media all and (max-width: 767px) and (orientation: landscape) {
            &:nth-child(2) {
                max-width: 30%;
            }
            &:nth-child(3) {
                max-width: 25%;
            }
            &:nth-child(4) {
              max-width: 25%;  
            }
        }
    }
}

.opt-in {
    > div {
        @apply flex flex-row items-baseline;
        span {
            @apply inline pl-0;
            svg path {
                @apply border border-bordeaux;
            }
        }
    }
}

.container {
    &:has(> .step2) + .portrait-wrap {
        img {
            &:nth-child(1) {
                @apply top-[75rem] md:top-[68rem] lg:top-3/4 my-0; 
            }
            &:nth-child(2) {
                @apply -top-[10rem] mt-0; 
            }
            &:nth-child(3) {
                @apply md:-bottom-[50rem] -bottom-[12rem] mt-0;
            }
            &:nth-child(4) {
                @apply md:-bottom-[72rem] lg:-bottom-[68rem] -bottom-[38rem] mt-0; 
            }
            @media all and (max-width: 767px) and (orientation: landscape) {
                &:nth-child(1) {
                    top: 68rem;  
                  }
                &:nth-child(4) {
                  bottom: -30rem;  
                }
            }
        }
    }
    &:has(> .thank-you) {
        + .portrait-wrap {
            img {
                &:nth-child(1) {
                    @apply top-[45rem] md:max-w-[8rem] md:top-[80%] md:mt-48 lg:top-[60%] mt-12; 
                }
                &:nth-child(2) {
                    @apply lg:right-1/4 top-12; 
                }
                &:nth-child(3) {
                    @apply md:max-w-xxs md:-bottom-[90%] lg:-bottom-[65%] bottom-32;
                }
                &:nth-child(4) {
                    @apply -bottom-36 md:-bottom-[115%] lg:-bottom-[90%] md:max-w-xxs; 
                }
                @media all and (max-width: 897px) and (orientation: landscape) {
                    &:nth-child(1) {
                        top: 33rem;
                    }
                    &:nth-child(4) {
                       bottom: -48rem;
                    }
                }
                @media all and (max-width: 741px) and (orientation: landscape) {
                    &:nth-child(1) {
                        top: 40rem;
                    }
                    &:nth-child(4) {
                       bottom: -4rem;
                    }
                }
            }
        }
        .form-wrap {
            @apply md:pb-96;
        }
    }
}
#root[aria-hidden="true"] {
    .App .step1 {
        label:has(.MuiInputBase-root) {
            @apply hidden text-red;
        }
    }
}