@font-face {
    font-family: 'Budweiser Script';
    src: url('../assets/fonts/BudweiserScript.woff');
    font-weight: 400;
}
@font-face {
    font-family: 'Budweiser Serif';
    src: url('../assets/fonts/BudweiserSerif-Display.woff');
    font-weight: 700;
}
@font-face {
    font-family: 'Budweiser Serif italic';
    src: url('../assets/fonts/BudweiserSerif-DisplayItalic.woff');
    font-weight: 700;
}
@font-face {
    font-family: 'Budweiser Serif';
    src: url('../assets/fonts/BudweiserSerif-Regular.woff');
    font-weight: 400;
}
@font-face {
    font-family: 'Budweiser Serif italic';
    src: url('../assets/fonts/BudweiserSerif-RegularItalic.woff');
    font-weight: 400;
}
@font-face {
    font-family: 'franklin-gothic-urw', sans-serif;
    font-style: normal;
    font-weight: 400;
    src: ('../assets/fonts/Franklin-Gothic-Book.woff');
}
@font-face {
    font-family: 'franklin-gothic-urw', sans-serif;
    font-style: normal;
    font-weight: 500;
    src: ('../assets/fonts/Franklin-Medium.woff');
}