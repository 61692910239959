@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
   @apply relative; 
    &.splash {
        @apply relative h-screen w-screen flex flex-col justify-center items-center text-center;

        button {
            @apply font-heading px-8 mt-0 text-3xl font-normal;
            span {padding-bottom: .2rem}
        }
    }
}

