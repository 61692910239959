@tailwind base;
@tailwind components;
@tailwind utilities;

section > *,
section {
    @apply my-8;
}
html {scroll-behavior: smooth;}
body {
    @apply bg-red text-white text-base font-body;  
}
h1,h2,h3,h4 {@apply font-heading my-4 uppercase;}
h1 {@apply text-5xl;}

h1,h2,h3,h4,button, img, p, a { @apply z-10 relative;}
button,
button[type="submit"],
a.button {
    @apply font-body uppercase border border-white text-white text-lg font-normal py-1 p-4 mt-8 min-w-[3rem] w-fit;

    transition: all .5s ease;

    &:hover {
        @apply bg-bordeaux;
        
        transition: all .5s ease;
    }
    &:visited {
        @apply bg-white text-red;

        &:hover {@apply bg-bordeaux;}
    }
    &:not(.get-started) {
        @apply flex items-center gap-4 uppercase;
        svg {@apply text-xs;}   
    }
}
button[type="submit"],
.get-started span {
    @apply bg-red;
}
.error-text {
    @apply block bg-black w-fit mx-auto my-8 p-2;
}
.App {
    @apply h-full w-full;
    
    .MuiTextField-root {
        @apply my-2;
        .MuiOutlinedInput-notchedOutline {@apply border-none;}
    }
    .MuiInputBase-root {
        @apply bg-white border-bordeaux border-2 rounded-xl ;
        svg {@apply text-red h-6 w-6 mr-4;}
    }
    .age-row .MuiInputBase-root svg {
        @apply mr-0;
    }
    .MuiFormLabel-root {
        @apply font-heading text-3xl font-bold text-darkgrey uppercase leading-none ;
        &.MuiInputLabel-animated {
            @apply text-xl;
            transform-origin: 0px 45px;
        }
        
    }
    .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
        @apply text-darkgrey leading-normal;
    }
    .MuiFormHelperText-root.Mui-error {@apply text-white;}

    .MuiFormControl-root {
        @apply relative w-full max-w-xs my-0;
       &.error {
            p {
                @apply text-white p-2 text-xs bg-black mt-3 ml-0 w-fit;
            }
            p.desc {
                @apply bg-transparent;
            }

            + .MuiFormHelperText-root {
                @apply text-white p-2 text-xs bg-black mt-0 ml-0 w-fit;
            }
        }
    }    

    .MuiMenuItem-root {
        @apply py-2;
        &:hover {
            @apply py-2 bg-lightgrey;
        }
    }

    &.inner-page {

        @apply pt-4;
        h1 {@apply normal-case font-bold mt-8 mb-10;}
        h2 {@apply text-3xl normal-case font-bold;}
        a {@apply font-bold underline;
            &.button {@apply no-underline;
            
                &:visited {@apply text-white;}
            }
        }
        #inner-page {
            @apply hidden;
        }
    }
    // &:has(.thank-you) footer {
    //     @apply fixed bottom-12;
    // }
}

.MuiInputBase-input.MuiOutlinedInput-input,
.MuiList-root .MuiButtonBase-root {@apply font-heading font-bold text-xl ;}

.MuiList-root .MuiButtonBase-root {@apply font-bold text-xl text-navy;}

.MuiInputBase-input.MuiOutlinedInput-input {@apply text-navy;}

.age-error { @apply text-white p-2 text-xs bg-black mt-3 ml-0 w-fit;}
span.step {
    @apply rounded-full py-1 px-3 uppercase font-medium text-xs border border-white;
}

